<script>

export default {
    name: 'textField',
    emits: {
        'updateValue'(property) {
            return property;
        }
    },
    props: {
        fieldData: {
            type: Object,
            required: true,

            default(rawProps) {
                console.log("rawProps:-", rawProps);

                return {};
            }
        },
        fieldId: Number
    },

    mounted() {
        console.log(this.fieldData.label);
        if (this.textValues.length === 0) {
            this.textValues.push({ value: '' });
            this.onValueChanged();
            console.log(this.textValues);
        }
    },

    data() {
        return {
            validationErroMsg: null,
            textValues: this.fieldData.values
        }
    },

    methods: {
        onValueChanged() {
            // console.log("textfield onValue changed", this);
            var updatedFieldData = this.fieldData;

            this.$emit("updateValue", updatedFieldData);
        },

        clearValidation() {
            this.validationErroMsg = null;
        },

        updateValidation(validation) {
            if (validation.propertyId === this.fieldId) {
                console.log("textfield: ", this.fieldId, " ", validation);
                this.validationErroMsg = validation.message;
                return true;
            }

            return false
        },

        addTextForm() {
            console.log("addTextForm", this.textValues);
            this.textValues.push({ value: '' });
            // var updatedFieldData = this.fieldData;
            //updatedFieldData.value = this.textValues.flatMap(el => el.value);
        },

        removeTextForm(index) {
            console.log('removing', this.textValues);
            this.textValues.splice(index, 1);
            console.log('removed', this.textValues);
            this.onValueChanged();
        },

        isAllowMultipleValues() {
            return typeof this.fieldData.allowMultipleValues !== 'undefined' &&
                this.fieldData.allowMultipleValues === true;
        }
    }
}
</script>

<template>
    <div class="row gy-4 pb-4">
        <div class="form-group col">
            <label :id="'input_' + fieldData.property" class="form-label">{{
                fieldData.label
            }}</label>

            <div v-if="fieldData.introInformation" v-html="fieldData.introInformation" />
            <!--start handling multiple values -->
            <div>
                <div v-for="(textValue, index) in textValues" :key="index" class="mb-2">

                    <input type="text" class="form-control" v-model="textValue.value" :id="'input_' + fieldData.property"
                        v-on:keyup="onValueChanged">
                    <div class="mt-2 mb-2">
                        <button v-show="index != 0" class="btn btn-light float-right " type="button"
                            title="Remove text field" @click="removeTextForm(index)">
                            <i class="ri-delete-bin-2-fill" style="font-size: x-large;"></i>
                        </button>
                    </div>
                </div>
            </div>
            <!--end handling multiple values-->

            <!--start validation error msg -->
            <div v-if="validationErroMsg !== null" class="text-danger">
                <small class="text-danger">{{ validationErroMsg }}</small>
            </div>
            <!--end validation error msg -->

        </div>
        <div v-if="isAllowMultipleValues()" class="row">
            <div class="col mt-2">
                <button class="btn btn-light float-end" type="button" title="Add text field" @click="addTextForm()">
                    <i class="ri-add-fill" style="font-size: x-large;"></i>
                </button>
            </div>
        </div>
    </div>
</template>