<script>

export default {
    name: 'valueChanged',
    emits: {
        'updateValue'(property) {
            return property;
        }
    },
    props: {
        fieldValues: {
            type: Array,
            required: true,

            default(rawProps) {
                console.log("rawProps:-", rawProps);

                return [];
            }
        },
        onValueChangedProperty: {
            type: Object,
            required: true
        }
    },

    watch: {

        fieldValues: {
            handler: function (newVal, oldVal) { // watch it
                console.log('Prop changed: ', newVal, ' | was: ', oldVal)
                this.onValueChanged();
            },
            deep: true
        }
    },

    mounted() {
        console.log("valueChanged");
        console.log(this.fieldData);
    },

    data() {
        return {
            validationErroMsg: null,
            propertiesToShow: [],
            featureComponentKey: ''
        }
    },

    methods: {


        clearValidation() {
            this.validationErroMsg = null;
        },

        updateValidation(validation) {
            console.log("valueChanged validation");
           return this.$refs.valueChangedFeature.updateValidation(validation);
        },


        onValueChanged() {
            //  var conditionStatements = this.onValueChangedProperty.conditionStatement;

            console.log("this.onValueChangedProperty: ", this.onValueChangedProperty);

            this.onValueChangedProperty.forEach(valueChanged => {
                console.log('feature key', this.featureComponentKey);
                var statement = valueChanged.conditionStatement;
                switch (statement.condition) {
                    case "equals":
                        console.log("fieldVal:", this.fieldValues);
                        if (this.fieldValues.find(x => {
                            return x.value.toString() === statement.expectedValue
                        }) !== undefined) {
                            console.log("matched value", valueChanged.properties);
                            this.propertiesToShow = valueChanged.properties;
                        }
                }
            });

        },
    }
}
</script>
        
<template>
    <div>
        <featureField :fieldData="propertiesToShow" ref="valueChangedFeature">
        </featureField>
    </div>

</template>