import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import AOS from 'aos'
import 'aos/dist/aos.css'
import i18n from './i18n'
import store from "./state/store";
import axios from 'axios';

import BootstrapVue3 from 'bootstrap-vue-3';
import vClickOutside from "click-outside-vue3"
import VueApexCharts from "vue3-apexcharts";
import Maska from 'maska';

import VueFeather from 'vue-feather';
import Particles from "particles.vue3";


import '@/assets/scss/config/creative/app.scss';
import '@vueform/slider/themes/default.css';

import selectField from "@/components/metadata/formFields/selectField.vue";
import textField from "@/components/metadata/formFields/textField.vue";
import urlField from "@/components/metadata/formFields/urlField.vue";
import dateField from "@/components/metadata/formFields/dateField.vue";
import timeField from "@/components/metadata/formFields/timeField.vue";
import nestedOptionsField from "@/components/metadata/formFields/nestedOptionsField.vue";
import nestedOptionFieldItem from "@/components/metadata/formFields/nestedOptionFieldItem.vue";
import groupField from "@/components/metadata/formFields/groupField.vue";
import checkboxField from "@/components/metadata/formFields/checkbox.vue";
import htmlField from "@/components/metadata/formFields/htmlField.vue";
import featureField from "@/components/metadata/formFields/featureField.vue";
import subFeatureField from "@/components/metadata/formFields/subFeature.vue";

axios.defaults.baseURL = '/';
axios.defaults.withCredentials = true;

AOS.init({
    easing: 'ease-out-back',
    duration: 1000
})

createApp(App)
    .use(store)
    .use(router)
    .use(VueApexCharts)
    .use(BootstrapVue3)
    .component(VueFeather.type, VueFeather)
    .use(Maska)
    .use(Particles)
    .use(i18n)
    .use(vClickOutside)
    .component("selectField", selectField)
    .component("textField", textField)
    .component("urlField", urlField)
    .component("dateField", dateField)
    .component("timeField", timeField)
    .component("groupField", groupField)
    .component("checkboxField", checkboxField)
    .component("htmlField", htmlField)
    .component("featureField", featureField)
    .component("nestedOptionsField", nestedOptionsField)
    .component("nestedOptionFieldItem", nestedOptionFieldItem)
    .component("subFeatureField", subFeatureField)
    .mount('#app');

