<script>
export default {
    name: 'groupField',

    emits: [ 'updateValue','addSubFeature' ],
    
    props: {
        fieldData: {
            type: Object,
            required: true,

            default(rawProps) {
                console.log("rawProps:-", rawProps);

                return {};
            }
        },
        groupIndex: {
            type: Number,
            required: true
        }
    },

    created() {
        console.log("!!group field created,  value:", this.fieldData);
        console.log(this.fieldData.properties);
    },

    methods: {
        updateValue(property) {
            console("groupField update value", property);
            this.$emit('updateValue', property);
        },

        shouldRender(element, form, expectedType) {
            // console.log("ffield: shouldRender");
            if (typeof form === 'undefined' || form === null) {
                return false;
            }

            return form.type === expectedType;
        },

        clearValidation() {
            console.log('feature val');
            for (var key in this.$refs) {
                // console.log("checking :", key);
                let featureComponent = this.$refs[key];
                if (typeof featureComponent !== 'undefined') {
                    featureComponent.clearValidation();
                }
            }
        },

        updateValidation(validation) {
            console.log('group field val, finding:', validation);

            if (typeof validation.groupIndex === 'undefined') {
                return;
            }

            var elementKey = 'formElement-' + validation.groupIndex + '-' + validation.propertyId;
            let featureComponent = this.$refs[elementKey];

            if (typeof featureComponent !== 'undefined') {
                featureComponent.updateValidation(validation);
            }
        },

        onAddSubFeature(subFeature, rootElement) {
            console.log("group field onAddSubFeature", subFeature, rootElement);
           this.$emit("addSubFeature", subFeature, rootElement);
        }
    }
}
</script>
    
<template>
    <div>
        <div v-for="(element) in fieldData.properties" :key="element.id">
            <div v-if="shouldRender(element, element.form, 'url')">
                <div class="row gy-4 pb-4">
                    <urlField :fieldData="element.form" :fieldId="element.id"
                        :ref="'formElement-' + groupIndex + '-' + element.id">
                    </urlField>
                </div>
            </div>
            <div v-if="shouldRender(element, element.form, 'text')">
                <textField :fieldData="element.form" :fieldId="element.id"
                    :ref="'formElement-' + groupIndex + '-' + element.id">
                </textField>
            </div>
            <div v-if="shouldRender(element, element.form, 'date')">
                <dateField :fieldData="element.form" :fieldId="element.id"
                    :ref="'formElement-' + groupIndex + '-' + element.id">
                </dateField>
            </div>
            <div v-if="shouldRender(element, element.form, 'time')">
                <timeField :fieldData="element.form" :fieldId="element.id" :ref="'formElement-' + element.id">
                </timeField>
            </div>
            <div v-if="shouldRender(element, element.form, 'options')">
                <selectField :fieldData="element.form" :fieldId="element.id"
                    :ref="'formElement-' + groupIndex + '-' + element.id">
                </selectField>
            </div>
            <div v-if="shouldRender(element, element.form, 'nestedOptions')">
                <nestedOptionsField :fieldData="element.form" :fieldId="element.id" :ref="'formElement-' + element.id">
                </nestedOptionsField>
            </div>
            <div v-if="shouldRender(element, element.form, 'checkbox')">
                <checkboxField :fieldData="element.form" :fieldId="element.id" :ref="'formElement-' + element.id">
                </checkboxField>
            </div>
            <div v-if="shouldRender(element, element.form, 'html')">
                <htmlField :fieldData="element.form" :fieldId="element.id" :ref="'formElement-' + element.id">
                </htmlField>
            </div>
            <div v-if="shouldRender(element, element.form, 'subFeature')">
                <subFeatureField :fieldData="element.form" :fieldId="element.id" :ref="'formElement-' + element.id"  @addSubFeature="onAddSubFeature">
                </subFeatureField>
            </div>
        </div>
    </div>
</template>