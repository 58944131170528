<script>
import api from '@/services/secureHttps';
import GroupContainer from '@/components/metadata/groupContainer.vue';
export default {
    name: 'subFeature',

    emits: ['addSubFeature'],

    props: {
        fieldData: {
            type: Object,
            required: true,

            default(rawProps) {
                console.log("rawProps:-", rawProps);

                return {};
            }
        },
        fieldId: Number
    },
    components: {
        GroupContainer
    },

    mounted() {
        console.log("SubFeatures mounted:", this.fieldData.label);
        /*if (this.textValues.length === 0) {
            this.textValues.push({ value: '' });
            this.onValueChanged();
            console.log(this.textValues);
        }*/
    },

    data() {
        return {
            editableFieldData: this.fieldData,
            validationErroMsg: null,
            // textValues: this.fieldData.values
        }
    },

    methods: {
        onValueChanged() {
            // console.log("textfield onValue changed", this);
            var updatedFieldData = this.fieldData;

            this.$emit("updateValue", updatedFieldData);
        },

        clearValidation() {
            this.validationErroMsg = null;
        },

        updateValidation(validation) {
            if (validation.propertyId === this.fieldId) {
                console.log("textfield: ", this.fieldId, " ", validation);
                this.validationErroMsg = validation.message;
                return true;
            }

            return false
        },

        addTextForm() {
            // console.log("addTextForm", this.textValues);
            this.textValues.push({ value: '' });
        },

        removeTextForm(index) {
            console.log('removing', this.textValues);
            this.textValues.splice(index, 1);
            console.log('removed', this.textValues);
            this.onValueChanged();
        },

        isAllowMultipleValues() {
            return typeof this.fieldData.allowMultipleValues !== 'undefined' &&
                this.fieldData.allowMultipleValues === true;
        },

        addFeature(event) {
            console.log('add feature', this.fieldData, event);
            event.preventDefault();

            api.getFeature(this.fieldData.urlPath)
                .then(response => {
                    console.log(response);
                    var subFeatureFormData = response.data;
                    if (typeof this.editableFieldData.components === 'undefined') {
                        this.editableFieldData.components = []
                    }

                    this.editableFieldData.components.push(subFeatureFormData);
                    this.$emit("addSubFeature", subFeatureFormData, this.editableFieldData);

                    event.target.scrollTo();
                    console.log("!!!Form data", this.fieldData);
                });
        },

        removeSubFeature(componentIndex) {
            if (componentIndex !== -1) {
                this.editableFieldData.components.splice(componentIndex, 1);
            }

        },

        onAddSubFeature(subFeature, rootElement) {
            console.log("subFeature onAddSubFeature", subFeature, rootElement);
            this.$emit("addSubFeature", subFeature, rootElement);
        },

        toggleSubFeatureExpand(event) {
            console.log('toggleSubFeatureExpand', event);
            let button = event.target;
            console.log(button.innerText);
            if (button.innerText == 'Minimize') {
                button.innerText = "Maximize";
                button.nextSibling.style.display = "none";
            } else {
                button.innerText = "Minimize";
                button.nextSibling.style.display = "block";
            }
        }

    }
}
</script>

<template>
    <div>
        <div class="row gy-4 pb-4">
            <div class="form-group col">
                <label :id="'input_' + fieldData.property" class="form-label">{{
                    fieldData.label
                }}</label>

                <div v-if="fieldData.introInformation" v-html="fieldData.introInformation" />

                <div>

                    <button class="btn btn-light float-right " type="button" :id="'input_' + fieldData.property"
                        @title="fieldData.label" @click="addFeature">
                        {{ fieldData.label }}
                    </button>
                </div>


                <!--start validation error msg -->
                <div v-if="validationErroMsg !== null" class="text-danger">
                    <small class="text-danger">{{ validationErroMsg }}</small>
                </div>
                <!--end validation error msg -->

            </div>
            <div v-if="isAllowMultipleValues()" class="row">
                <div class="col mt-2">
                    <button class="btn btn-light float-end" type="button" title="Add text field" @click="addTextForm()">
                        <i class="ri-add-fill" style="font-size: x-large;"></i>
                    </button>
                </div>
            </div>
        </div>

        <div v-if="fieldData.components" style="background-color: gainsboro;">

            <div v-for="(component, index) in fieldData.components" :key="component.id">
                <div class="row gy-4 pb-4 ps-3 pe-3">
                    <div class="form-group col">
                        <button class="btn btn-light float-right mb-2 " type="button" @title="fieldData.label"
                            @click="toggleSubFeatureExpand">
                            Minimize
                        </button>
                        <button class="btn btn-light float-right ms-2 mb-2 " type="button" @title="fieldData.label"
                                    @click="removeSubFeature(index)">
                                    <i class="ri-delete-bin-2-fill" style="font-size: x-large;"></i>
                                </button>
                        <div class="groupContainer">
                            <GroupContainer :groups="component.groups" ref="formBuilder"
                                @groupElementAdded="onNewElementAddded" @addSubFeature="onAddSubFeature">
                            </GroupContainer>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>