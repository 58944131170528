<template>
  <router-view></router-view>

</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'App',

  created() {
    try {
      this.restoreContext()
        .catch(() => {} );
    } catch  {
      //console.log('Caught by try/catch ${error}');
    }
  },
  methods: {
    ...mapActions('context', [
      'restoreContext'
    ])
  }
}
</script>