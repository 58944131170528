<script>
import { mapGetters } from 'vuex'

export default {
  name: 'nestedOptionFieldItem', // necessary for self-reference

  props: {
    model: Object,
    depth: Number,
    selectedValues: Array
  },

  data() {
    return {
      // isOpen: false,
      data: this.model
    }
  },

  mounted() {

    console.log("nestedOptionFieldItem mounted");
  },
  computed: {
    ...mapGetters('context', [
      'selectedWebisteId'
    ]),
    isFolder() {
      //console.log("isFolder: ", this.data);
      return this.data && this.data.subOptions && this.data.subOptions.length;
    }

  },

  methods: {
    toggle() {
      console.log("toggle", this.isFolder);
      console.log(this.data);
      if (this.isFolder) {

        this.data.isOpen = !this.data.isOpen;
        this.data.subOptions.forEach(element => {
          element.isVisible = !element.isVisible;
        });
      }
    },

    changeType() {
      if (!this.isFolder) {
        this.data.subOptions = [];
        this.data.isOpen = true;
      }
    },

    paddBasedOnDepth() {
      if (this.depth == 1) {
        return '';
      }

      var style = `padding-left:` + (this.depth + 0.5) + 'em';

      return style;
    },

    onbusinesTypeSelected(event, option) {
      console.log("onbusinesTypeSelected", option)
        this.$emit("optionSelected", option);

        event.stopPropagation();
    },

    onNestedOptionSelected(option)
    {
      this.$emit("optionSelected", option);
    },

     isSelected(checkItemModel) {
        return this.selectedValues.some(value => value.value === checkItemModel.id);
     }
  }
}
</script>

<template>
  <div>
    <div :class="[isFolder ? 'fw-medium' : 'text-muted']" @click="toggle(model.id)" @dblclick="changeType"
      class="highlightHover form-check" :style="paddBasedOnDepth()">

      <span v-if="isFolder"><span v-if="data.isOpen" class="mdi mdi-chevron-down"></span>
        <span v-else class="mdi mdi-chevron-right"></span>
      </span>
      <span><input type="checkbox" class="form-check-inline"  @click="onbusinesTypeSelected($event, model)" :checked="isSelected(model)"></span>{{
        data.label }}
    </div>

    <template v-if="data.isFolder" id="">
      <!--
              A component can recursively render itself using its
              "name" option (inferred from filename if using SFC)
            -->
      <nestedOptionFieldItem  @optionSelected="onNestedOptionSelected" v-show="data.isOpen" v-for="model in data.subOptions" :model="model" :key="model.id"
        :depth="depth + 1" :selectedValues="selectedValues">
      </nestedOptionFieldItem>
    </template>

  </div>
</template>

<style scoped>
.basicList {
  list-style-type: none;
}

.noBorder {
  border: none
}

.highlightHover:hover {
  text-decoration: underline;
}

.icon {
  text-decoration: none;
  float: right;
}
</style>

