<script>

export default {
    name: 'selectField',

    emits: {
        'updateValue'(property) {
            console.log('property received', property);
            return property;
        }
    },

    props: {
        fieldData: {
            type: Object,
            required: true,

            default(rawProps) {
                console.log("rawProps:-", rawProps);
                return {};
            }
        },
        fieldId: Number
    },

    data() {
        return {
            selectedForm: [],
            validationErroMsg: null
        }
    },

    created() {
        console.log("option value:", this.fieldData);
    },

    methods: {

        onOptionChanged(e) {
            let selectedOption = this.fieldData.options.find(x => x.id === e.target.value)
            console.log("selected option", e.target.value, this.fieldData.options);
            if (typeof selectedOption !== 'undefined') {

                var updatedFieldData = this.fieldData;
                updatedFieldData.values = [{ value: e.target.value }];

                console.log('updatedFieldData', updatedFieldData);
                this.$emit("updateValue", updatedFieldData);
                console.log('selected selectedForm:', updatedFieldData);
            }
        },

        hasProperties(element) {
            console.log('select field prop', element);
            if (typeof element.properties === 'undefined' || element.properties === null) {
                return false;
            }

            console.log("select field found proerties", element);
            return true;
        },

        clearValidation() {
            this.validationErroMsg = null;
            let component = this.$refs.feature;
            if (typeof component !== 'undefined') {
                component.clearValidation();
            }
        },

        updateValidation(validation) {
            console.log("selectField val", validation);

            console.log("this.fieldData.id", this.fieldId);

            if (validation.propertyId === this.fieldId) {
                this.validationErroMsg = validation.message;
                console.log("validationErroMsg", this.validationErroMsg);
            } else {
                let component = this.$refs.feature;
                // console.log("checking features in select :", this.$refs.feature);
                if (typeof component !== 'undefined' && component !== null) {
                    // console.log("checking :", component);
                    component.updateValidation(validation);
                }
            }

        },

        getSelectedValue() {
            if (typeof this.fieldData === 'undefined') {
                return "noneSelected";
            }

            if (typeof this.fieldData.values !== 'undefined' && 
                    this.fieldData.values !== null &&
                    this.fieldData.values.length >= 1) {

                var selectedValue = this.fieldData.values[0];

                if (typeof selectedValue !== 'undefined' && selectedValue !== null) {
                        return selectedValue.value;
                }
            }

            return "noneSelected";
        }
    }
}
</script>
<template>
    <div>
        <div class="col mb-3">
            <div>{{ fieldData.label }}</div>
            <div v-if="fieldData.introInformation" v-html="fieldData.introInformation" />
            <select @change="onOptionChanged" class="form-select">
                <option value="noneSelected">Select option</option>
                <option v-for="option in fieldData.options" :key="option.id" :value="option.id"
                    :selected="option.id === getSelectedValue()">
                    {{ option.label }}
                </option>
            </select>
            <div v-if="validationErroMsg !== null" class="text-danger">
                <small class="text-danger">{{ validationErroMsg }}</small>
            </div>
        </div>
        <div v-if="hasProperties(fieldData)">
            <featureField :fieldData="fieldData.properties" ref="feature"></featureField>

        </div>
    </div>

</template>
