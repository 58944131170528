<script>
import MetaDataFormBuilder from '@/components/metadata/metaDataFormBuilder.vue'
import { toRaw } from 'vue';

export default {
    name: 'groupContainer',
    emits: ['newElementAdded', 'addSubFeature'],

    props: {
        groups: {
            type: Array,
            required: true,

            default(rawProps) {
                console.log("rawProps:-", rawProps);

                return [];
            }
        }
    },
    mounted() {
        console.log('group container mounted', this.groups);
    },

    components: {
        MetaDataFormBuilder
    },
    data() {
        return {
            formVariable: this.groups,
        }
    },

    methods: {
        shouldRender(element, form, expectedType) {
            if (typeof form === 'undefined' || form === null) {
                console.log("not rendering");
                return false;
            }

            return typeof form.type !== 'undefined' && form.type !== null && form.type === expectedType;
        },
        hasProperties(element) {
            //console.log("element", element);

            if (typeof element.properties === 'undefined' || element.properties === null) {
                return false;
            }

            return true;
        },

        clearValidation() {
            for (var key in this.$refs) {
                let featureComponent = this.$refs[key];

                if (typeof featureComponent !== 'undefined' && featureComponent !== null) {
                    featureComponent.clearValidation();
                }
            }
        },

        updateValidation(validation) {
            //console.log("gc", validation);
            //console.log("gc", this.$refs);
            for (var key in this.$refs) {
                //console.log("gc checking :", key);
                let featureComponent = this.$refs[key];
                if (featureComponent !== null) {
                    var isMatch = featureComponent.updateValidation(validation);
                    if (isMatch === true) {
                        break;
                    }
                }
            }
        },

        isAllowMultipleValues(group) {
            console.log("isAllowMultiple:", group);
            return typeof group.allowMultipleValues !== 'undefined' &&
                group.allowMultipleValues === true;
        },

        addGroupForm(group) {
            var itemsCopy = toRaw(group.properties).slice();
            var groupClone = JSON.parse(JSON.stringify(itemsCopy[0]));

            this.updateIdRecursive(groupClone);
            group.properties.push(groupClone);

            console.log("group container, added group form");

            this.$emit('groupElementAdded');
        },

        updateIdRecursive(obj) {
            for (var k in obj) {
                if (typeof obj[k] == "object" && obj[k] !== null) {
                    this.updateIdRecursive(obj[k]);
                }
                else {
                    if (k === "id") {
                        obj[k] = obj[k] + 1000000;
                    }

                    if (k === "value") {
                        // clear out values from copied form
                        obj[k] = [];
                    }
                }
            }
        },

        OnElementsChanged() {
            this.$emit('groupElementAdded');
        },

        onAddSubFeature(subFeature, rootElement) {
            console.log("Group container onAddSubFeature", subFeature, rootElement);
            this.$emit("addSubFeature", subFeature, rootElement);
        }
    }
}

</script>

<template>
    <div>
        <div v-for="group in groups" :key="group.id">
            <MetaDataFormBuilder :formData="group.properties" :title="group.label" :ref="'groupContainer-' + group.id"
                @elementAdded="OnElementsChanged" @elementRemoved="OnElementsChanged" @addSubFeature="onAddSubFeature">
            </MetaDataFormBuilder>

            <!-- start add group-->
            <div v-if="isAllowMultipleValues(group)" class="row mt-n3">
                <div class="col-xl-12">
                    <button class="btn btn-light float-end" type="button" title="Add groupd" @click="addGroupForm(group)">
                        <i class="ri-add-fill" style="font-size: x-large;"></i>
                    </button>
                </div>
            </div>
            <!--End group-->

            <div v-if="typeof group.recommendations !== 'undefined' && group.recommendations !== null && group.recommendations.length > 0"
                class="row">
                <div class="col-xl-12 padding">
                    <!--start recommendation card-->
                    <div class="card card border card-border-info">
                        <div class="card-header">
                            <div class="d-flex align-items-center">
                                <div class="d-flex align-items-center">
                                    <span class="display-5 d-inline-flex align-items-center">
                                        <i class="ri-information-fill text-info me-2 ri-6"></i>
                                        <h6 class="card-title mb-0">{{ group.label }} recommendations</h6>
                                    </span>
                                </div>

                                <div id="icon" class="flex-shrink-0 ms-auto">
                                    <ul class="list-inline card-toolbar-menu d-flex align-items-center mb-0">
                                        <li class="list-inline-item">
                                            <a class="align-middle minimize-card collapsed" data-bs-toggle="collapse"
                                                :href="'#recommendation-' + group.id" role="button" aria-expanded="false"
                                                :aria-controls="'recommendation-' + group.id">
                                                <i class="mdi mdi-plus align-middle plus"></i>
                                                <i class="mdi mdi-minus align-middle minus"></i>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="card-body collapse" :id="'recommendation-' + group.id">
                            <div v-for="recommendation in group.recommendations" :key="recommendation.id"
                                class="d-flex mt-2">
                                <div class="flex-shrink-0"><i class="ri-information-fill text-info"></i></div>
                                <div class="flex-grow-1 ms-2 text-muted" v-html="recommendation.Message"></div>
                            </div>

                        </div>
                    </div>
                    <!-- end recommendation card -->

                </div>
            </div>

        </div>
    </div>
</template>


