<script>
import flatPickr from "vue-flatpickr-component";

export default {
    name: 'dateField',
    emits: {
        'updateValue'(property) {
            return property;
        }
    },

    props: {
        fieldData: {
            type: Object,
            required: true,

            default(rawProps) {
                console.log("rawProps:-", rawProps);

                return {};
            }
        },
        fieldId: Number
    },
    components: {
        flatPickr
    },

    mounted() {
        if (this.selectedDate.length === 0) {
            this.selectedDate.push({ value: '' });
            this.onDateChanged();
            console.log(this.selectedDate);
        }
    },
    data() {
        return {
            selectedDate: this.fieldData.values,
            defaultDateConfig: {
                dateFormat: "d M, Y",
                date: null
            },
            validationErroMsg: null,
           // dateValues: this.fieldData.values
        }
    },
    methods: {
        onDateChanged() {
            var updatedFieldData = this.fieldData;
          //  updatedFieldData.value = this.selectedDate.flatMap(el => el.value);
            this.$emit("updateValue", updatedFieldData);
        },

        clearValidation() {
            this.validationErroMsg = null;
        },

        updateValidation(validation) {
            console.log("dateField: ", validation);
            console.log("id:", this.fieldId);
            if (validation.propertyId === this.fieldId) {
                console.log("dateField val", validation);
                this.validationErroMsg = validation.message;
                return true;
            } else {
                return false;
            }
        },

        addDateForm() {
            console.log("addDateForm", this.selectedDate);
            this.selectedDate.push({value: '' });
            var updatedFieldData = this.fieldData;
            updatedFieldData.value = this.selectedDate.flatMap(el => el.value);
        },

        removeDateForm(index) {
            console.log('removing', this.selectedDate);
            this.selectedDate.splice(index, 1);
            console.log('removed', this.selectedDate);
            this.onDateChanged();
        },

        isAllowMultipleValues() {
            return typeof this.fieldData.allowMultipleValues !== 'undefined' &&
                this.fieldData.allowMultipleValues === true;
        }
    }
}
</script>

<template>
    <div class="row gy-4 pb-4">
        <div class="form-group col-xxl-6">
            <div><label class="form-label mb-0">{{ fieldData.label }}</label>
                <div v-for="(dateVal, index) in selectedDate" :key="index" class="mb-2">

                    <flat-pickr v-model="dateVal.value" :config="defaultDateConfig" @input="onDateChanged"
                        class="form-control flatpickr-input">
                    </flat-pickr>
                    <button v-show="index != 0" class="btn btn-light float-right " type="button" title="Remove image"
                        @click="removeDateForm(index)">
                        <i class="ri-delete-bin-2-fill" style="font-size: x-large;"></i>
                    </button>
                </div>
            </div>
            <div v-if="validationErroMsg !== null" class="text-danger">
                <small class="text-danger">{{ validationErroMsg }}</small>
            </div>
        </div>

        <div v-if="isAllowMultipleValues()" class="row">
            <div class="col-xxl-6 mt-2">
                <button class="btn btn-light float-end" type="button" title="Add image" @click="addDateForm()">
                    <i class="ri-add-fill" style="font-size: x-large;"></i>
                </button>
            </div>
        </div>
    </div>
</template>