<script>
export default {
    name: 'nestedOptions',

    emits: {
        'updateValue'(property) {
            console.log('property received', property);
            return property;
        }
    },

    props: {
        fieldData: {
            type: Object,
            required: true,

            default(rawProps) {
                console.log("rawProps:-", rawProps);
                return {};
            }
        },
        fieldId: Number
    },

    watch: {
        fieldData: {
            handler: function (newVal, oldVal) { // watch it
                console.log('Prop changed: ', newVal, ' | was: ', oldVal)
                this.setupOptionsData(newVal, true);
            },
            deep: true
        }
    },

    data() {
        return {
            selectedForm: [],
            validationErroMsg: null
        }
    },

    mounted() {
        console.log("nested option value:", this.fieldData);
        this.setupOptionsData(this.fieldData.options, true);
    },

    methods: {

        setupOptionsData(options, isVisible) {
            if (options === null) {
                return;
            }

           // console.log("setupOptionsData", options);
            for (let i = 0; i < options.length; i++) {
            //    console.log("options:", options[i])
                let item = options[i];
                item.isVisible = isVisible;

                // to remove
                item.isFolder = true;
                //end to remove

                if (typeof item.subOptions !== 'undefined') {
                    item.isFolder = true;
                    item.isOpen = false;

                    this.setupOptionsData(item.subOptions, false);
                }
            }
        },

        onOptionChanged(e) {
            let selectedOption = this.fieldData.options.find(x => x.id === e.target.value)
            console.log("selected option", e.target.value, this.fieldData.options);
            if (typeof selectedOption !== 'undefined') {

                var updatedFieldData = this.fieldData;
                updatedFieldData.values = [{ value: e.target.value }];

                console.log('updatedFieldData', updatedFieldData);
                this.$emit("updateValue", updatedFieldData);
                console.log('selected selectedForm:', updatedFieldData);
            }
        },

        hasProperties(element) {
            console.log('selected item prop', element);
            if (typeof element.properties === 'undefined' || element.properties === null) {
                return false;
            }

            console.log("select field found proerties", element);
            return true;
        },

        clearValidation() {
            this.validationErroMsg = null;
            let component = this.$refs.feature;
            if (typeof component !== 'undefined') {
                component.clearValidation();
            }
        },

        updateValidation(validation) {
            console.log("selectField val", validation);

            console.log("this.fieldData.id", this.fieldId);

            if (validation.propertyId === this.fieldId) {
                this.validationErroMsg = validation.message;
                console.log("validationErroMsg", this.validationErroMsg);
            } else {
                let component = this.$refs.feature;
                // console.log("checking features in select :", this.$refs.feature);
                if (typeof component !== 'undefined' && component !== null) {
                    // console.log("checking :", component);
                    component.updateValidation(validation);
                }
            }

        },

        getSelectedValue() {
            if (typeof this.fieldData === 'undefined') {
                return "noneSelected";
            }

            if (typeof this.fieldData.values !== 'undefined' &&
                this.fieldData.values !== null &&
                this.fieldData.values.length >= 1) {

                var selectedValue = this.fieldData.values[0];

                if (typeof selectedValue !== 'undefined' && selectedValue !== null) {
                    return selectedValue.value;
                }
            }

            return "noneSelected";
        },

        onOptionSelected(selectedOption) {
            console.log("nestedOptionFiled onOptionSelected", selectedOption);

            var updatedFieldData = this.fieldData;
            if(typeof updatedFieldData.values === 'undefined' || updatedFieldData.values === null) {
                updatedFieldData.values = [];
            }

            updatedFieldData.values.push({value: selectedOption.id} );   
            
            console.log('updatedFieldData', updatedFieldData);
            this.$emit("updateValue", updatedFieldData);
        }
    }
}
</script>
<template>
    <div>
        <div class="col mb-3">
            <div>{{ fieldData.label }}</div>
            <div style="height:350px; overflow:auto; width:100%">
                <nestedOptionFieldItem :model="option" @optionSelected="onOptionSelected" :depth="1" :selectedValues="fieldData.values" v-for="option in fieldData.options" :key="option.id">
                </nestedOptionFieldItem>
            </div>
            <div v-if="validationErroMsg !== null" class="text-danger">
                <small class="text-danger">{{ validationErroMsg }}</small>
            </div>
        </div>

    </div>
</template>

<style scoped>
.optionGroup {
    font-weight: bold;
    font-style: italic;
}

.optionChild {
    padding-left: 15px;
}
</style>
