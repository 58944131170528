<script>

import { VueEditor } from "vue3-editor";

export default {
    name: 'htmlField',
    components: {
        VueEditor
    },
    emits: {
        'updateValue'(property) {
            return property;
        }
    },
    props: {
        fieldData: {
            type: Object,
            required: true,

            default(rawProps) {
                console.log("rawProps:-", rawProps);

                return {};
            }
        },
        fieldId: Number
    },

    mounted() {
        console.log("html editor");
        if (this.htmlValues.length === 0) {
            this.htmlValues.push({ value: '' });
            this.onValueChanged();
        }
    },

    data() {
        return {
            validationErroMsg: null,
            htmlValues: this.fieldData.values,
            customToolbar: [
                [{ list: "bullet" }]
            ]
        }
    },

    methods: {
        onValueChanged() {
            console.log("html editor onValue changed", this);
            var updatedFieldData = this.fieldData;

            this.$emit("updateValue", updatedFieldData);
        },

        clearValidation() {
            this.validationErroMsg = null;
        },

        updateValidation(validation) {
            if (validation.propertyId === this.fieldId) {
                console.log("checkbox: ", this.fieldId, " ", validation);
                this.validationErroMsg = validation.message;
                return true;
            }

            return false
        },
        isAllowMultipleValues() {
            return typeof this.fieldData.allowMultipleValues !== 'undefined' &&
                this.fieldData.allowMultipleValues === true;
        },
    }
}
</script>
        
<template>
    <div class="row gy-4 pb-4">
        <div class="form-group col-xxl-6">
            <label :id="'input_' + fieldData.property" class="form-label">{{
            fieldData.label
            }}</label>
            <!--start handling multiple values -->
            <div>
                <div v-for="(html, index) in htmlValues" :key="index" class="mb-2">

                    <VueEditor :id="'editor-' + index" v-model="html.value" :editor-toolbar="customToolbar"></VueEditor>
                    <div class="mt-2 mb-2">
                        <button v-show="index != 0" class="btn btn-light float-right " type="button"
                            title="Remove text field" @click="removeTextForm(index)">
                            <i class="ri-delete-bin-2-fill" style="font-size: x-large;"></i>
                        </button>
                    </div>
                </div>
            </div>
            <!--end handling multiple values-->

            <!--start validation error msg -->
            <div v-if="validationErroMsg !== null" class="text-danger">
                <small class="text-danger">{{ validationErroMsg }}</small>
            </div>
            <!--end validation error msg -->

        </div>
        <div v-if="isAllowMultipleValues()" class="row">
            <div class="col-xxl-6 mt-2">
                <button class="btn btn-light float-end" type="button" title="Add text field" @click="addTextForm()">
                    <i class="ri-add-fill" style="font-size: x-large;"></i>
                </button>
            </div>
        </div>

    </div>

</template>