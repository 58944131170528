<script>
export default {
    name: 'metaDataFormBuilder',

    emits: [ 'updateValue','addSubFeature' ],

    props: {
        formData: {
            type: Array,
            required: true,

            default(rawProps) {
                console.log("rawProps:-", rawProps);

                return [];
            }
        },
        title: null
    },
    created() {
        console.log('metadataform-builder created');
    },

    components: {

    },
    data() {
        return {
            formVariable: this.formData,
        }
    },

    methods: {
        updateValue(property) {
            //console.log("updateValue - property", property);
            this.$emit('updateValue', property);
        },

        shouldRender(element, form, expectedType) {
            //console.log("element2:", element);
            //console.log("form", form);
            //console.log("excpectedType:", expectedType);

            if (typeof form === 'undefined' || form === null) {
                console.log("not rendering");
                return false;
            }

            return typeof form.type !== 'undefined' && form.type !== null && form.type === expectedType;
        },
        hasProperties(element) {
            //console.log("element", element);

            if (typeof element.properties === 'undefined' || element.properties === null) {
                return false;
            }

            console.log("meta data form builder found proerties", element);
            return true;
        },

        clearValidation() {
            for (var key in this.$refs) {
                let featureComponent = this.$refs[key];

                if (typeof featureComponent !== 'undefined' && featureComponent !== null) {
                    featureComponent.clearValidation();
                }
            }
        },

        updateValidation(validation) {
            // console.log("mdfb validation:", validation)
            // console.log("mdfb", this.$refs);
            for (var key in this.$refs) {
                console.log("mdfb checking :", key);
                let featureComponent = this.$refs[key];

                if (featureComponent !== null) {
                    var isMatch = featureComponent.updateValidation(validation);
                    if (isMatch === true) {
                        break;
                    }
                }
            }
        },

        onAddSubFeature(subFeature, rootElement) {
            console.log("MetaData form builder onAddSubFeature", subFeature, rootElement);
           this.$emit("addSubFeature", subFeature, rootElement);
        }
    }
}

</script>

<template>

    <div>

        <div class="card">
            <div class="card-header">
                <h2 class="mb-0" style="margin: 0 0 7px 0;">{{title}}</h2>
            </div>
            <!-- end card header -->

            <div class="card-body">

                <div class="row">
                    <div class="col">
                        <div v-for="(element, index) in formData" :key="element.id">
                            <div v-if="shouldRender(element, element.form, 'url')">
                                <div>
                                    <urlField :fieldData="element.form" :fieldId="element.id"
                                        :ref="'formElement-' + element.id">
                                    </urlField>
                                </div>
                            </div>
                            <div v-if="shouldRender(element, element.form, 'text')">
                                <textField :fieldData="element.form" :fieldId="element.id"
                                    :ref="'formElement-' + element.id">
                                </textField>
                            </div>
                            <div v-if="shouldRender(element, element.form, 'date')">
                                <dateField :fieldData="element.form" :fieldId="element.id"
                                    :ref="'formElement-' + element.id">
                                </dateField>
                            </div>
                            <div v-if="shouldRender(element, element.form, 'time')">
                                <timeField :fieldData="element.form" :fieldId="element.id"
                                    :ref="'formElement-' + element.id">
                                </timeField>
                            </div>
                            <div v-if="shouldRender(element, element.form, 'options')">
                                <selectField :fieldData="element.form" :fieldId="element.id"
                                    :ref="'formElement-' + element.id"></selectField>
                            </div>
                            <div v-if="shouldRender(element, element.form, 'nestedOptions')">
                                <nestedOptionsField :fieldData="element.form" :fieldId="element.id"
                                    :ref="'formElement-' + element.id"></nestedOptionsField>
                            </div>
                            <div v-if="shouldRender(element, element.form, 'checkbox')">
                                <checkboxField :fieldData="element.form" :fieldId="element.id"
                                    :ref="'formElement-' + element.id">
                                </checkboxField>
                            </div>
                            <div v-if="shouldRender(element, element.form, 'html')">
                                <htmlField :fieldData="element.form" :fieldId="element.id"
                                    :ref="'formElement-' + element.id">
                                </htmlField>
                            </div>
                            <!-- <div v-if="shouldRender(element.form, 'Feature')">
           <featureField :fieldData="element.form"   :ref="'formElement-' + element.id"></featureField>
        </div>-->
                            <div v-if="shouldRender(element, element.form, 'group')">
                                <div class="row gy-4 pb-4">
                                    <groupField :fieldData="element.form" :fieldId="element.id"
                                        :ref="'formElement-' + element.id" :groupIndex="index" @addSubFeature="onAddSubFeature">
                                    </groupField>
                                </div>
                            </div>

                            <div v-if="shouldRender(element, element.form, 'subFeature')">
                                <subFeatureField :fieldData="element.form" :fieldId="element.id"
                                    :ref="'formElement-' + element.id" @addSubFeature="onAddSubFeature">
                                </subFeatureField>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>


