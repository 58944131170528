<script>

export default {
    name: 'featureField',

    emits: [ 'updateValue','addSubFeature' ],

    props: {
        fieldData: {
            type: Array,
            required: true,

            default(rawProps) {
                console.log("rawProps:-", rawProps);

                return [];
            }
        }
    },

    created() {
        console.log("field created,  value:", this.fieldData);
    },

    methods: {
        updateValue(property) {
            this.$emit('updateValue', property);
        },

        shouldRender(element, form, expectedType) {
            //console.log("ffield: shouldRender", expectedType);
            if (typeof form === 'undefined' || form === null) {
                return false;
            }

            // console.log("fField:", element.id, form, expectedType);

            return form.type === expectedType;
        },

        hasProperties(element) {
            if (typeof element.properties === 'undefined' || element.properties === null) {
                return false;
            }

            console.log("featureField, fournd properties");

            return true;
        },

        clearValidation() {
            console.log('feature val');
            for (var key in this.$refs) {
                // console.log("checking :", key);
                let featureComponent = this.$refs[key];
                if (typeof featureComponent !== 'undefined') {
                    featureComponent.clearValidation();
                }
            }
        },

        updateValidation(validation) {
            console.log('ffield val, finding:', validation);

            for (var key in this.$refs) {
                //  console.log("checking :", key);
                let featureComponent = this.$refs[key];
                if (featureComponent !== null) {
                    var isMatch = featureComponent.updateValidation(validation);
                    if (isMatch === true) {
                        break;
                    }
                }
            }
        },

        onAddSubFeature(subFeature, rootElement) {
            console.log("feature field onAddSubFeature", subFeature, rootElement);
           this.$emit("addSubFeature", subFeature, rootElement);
        }
    }
}
</script>

<template>
    <div>
        <div>{{ fieldData.label }}</div>
        <div v-for="element in fieldData" :key="element.id">
            <div v-if="shouldRender(element, element.form, 'url')">
                <div class="row gy-4 pb-4">
                    <urlField :fieldData="element.form" :fieldId="element.id" :ref="'formElement-' + element.id">
                    </urlField>
                </div>
            </div>
            <div v-if="shouldRender(element, element.form, 'text')">
                <textField :fieldData="element.form" :fieldId="element.id" :ref="'formElement-' + element.id">
                </textField>
            </div>
            <div v-if="shouldRender(element, element.form, 'date')">
                <dateField :fieldData="element.form" :fieldId="element.id" :ref="'formElement-' + element.id">
                </dateField>
            </div>
            <div v-if="shouldRender(element, element.form, 'time')">
                <timeField :fieldData="element.form" :fieldId="element.id" :ref="'formElement-' + element.id">
                </timeField>
            </div>
            <div v-if="shouldRender(element, element.form, 'options')">
                <selectField :fieldData="element.form" :fieldId="element.id" :ref="'formElement-' + element.id">
                </selectField>
            </div>
            <div v-if="shouldRender(element, element.form, 'nestedOptions')">
                <nestedOptionsField :fieldData="element.form" :fieldId="element.id" :ref="'formElement-' + element.id">
                </nestedOptionsField>
            </div>
            <div v-if="shouldRender(element, element.form, 'checkbox')">
                <checkboxField :fieldData="element.form" :fieldId="element.id" :ref="'formElement-' + element.id">
                </checkboxField>
            </div>
            <div v-if="shouldRender(element, element.form, 'html')">
                <htmlField :fieldData="element.form" :fieldId="element.id" :ref="'formElement-' + element.id">
                </htmlField>
            </div>

            <!-- <div v-if="shouldRender(element.form, 'Feature')">
               <featureField :fieldData="element.form" ></featureField>
            </div>-->
            <div v-if="hasProperties(element)">
                <featureField :fieldData="element.properties" :ref="'formElement-' + element.id">
                </featureField>
            </div>
            <div v-if="shouldRender(element, element.form, 'subFeature')">
                                <subFeatureField :fieldData="element.form" :fieldId="element.id"
                                    :ref="'formElement-' + element.id"  @addSubFeature="onAddSubFeature">
                                </subFeatureField>
                            </div>
        </div>
    </div>
</template>