<script>

import ValueChanged from "@/components/metadata/formFields/valueChanged.vue";

export default {
    name: 'checkbox',
    components: {
        ValueChanged
    },
    emits: {
        'updateValue'(property) {
            return property;
        }
    },
    props: {
        fieldData: {
            type: Object,
            required: true,

            default(rawProps) {
                console.log("rawProps:-", rawProps);

                return {};
            }
        },
        fieldId: Number
    },

    mounted() {
        console.log("checkbox");
        console.log(this.fieldData.label);
        console.log(this.fieldData);
        if (this.cbValues.length === 0) {
            this.cbValues.push({ value: false });
            this.onValueChanged();
            console.log(this.cbValues);
        }
    },

    data() {
        return {
            validationErroMsg: null,
            cbValues: this.fieldData.values
        }
    },

    methods: {
        onValueChanged() {
            console.log("checkbox onValue changed", this);
            var updatedFieldData = this.fieldData;
            // this.cbValues[0].value = this.checked;
            //  updatedFieldData.value = this.textValues.flatMap(el => el.value);
            //  console.log("updatedFieldData: ", updatedFieldData.value);

            this.$emit("updateValue", updatedFieldData);
        },

        clearValidation() {
            this.validationErroMsg = null;
        },

        updateValidation(validation) {
            if (typeof this.$refs.conditionalElement !== 'undefined') {
                this.$refs.conditionalElement.updateValidation(validation);
            }

            if (validation.propertyId === this.fieldId) {
                console.log("checkbox: ", this.fieldId, " ", validation);
                this.validationErroMsg = validation.message;
                return true;
            }

            return false
        },

        isAllowMultipleValues() {
            return typeof this.fieldData.allowMultipleValues !== 'undefined' &&
                this.fieldData.allowMultipleValues === true;
        },

        hasCondition() {
            console.log("cb has con:", typeof this.fieldData.onValueChanged !== 'undefined' && this.fieldData.onValueChanged !== null);
            return typeof this.fieldData.onValueChanged !== 'undefined' && this.fieldData.onValueChanged !== null
        }
    }
}
</script>
    
<template>
    <div class="row gy-4 pb-4">
        <div class="form-group col-xxl-6">
            <label :id="'input_' + fieldData.property" class="form-label">{{
            fieldData.label
            }}</label>
            <!--start handling multiple values -->
            <div>
                <div v-for="(cbValue, index) in cbValues" :key="index" class="mb-2">

                    <input type="checkbox" class="form-check-input" v-model="cbValue.value"
                        :id="'input_' + fieldData.property" @change="onValueChanged()">
                    <div class="mt-2 mb-2">
                        <button v-show="index != 0" class="btn btn-light float-right " type="button"
                            title="Remove text field" @click="removeTextForm(index)">
                            <i class="ri-delete-bin-2-fill" style="font-size: x-large;"></i>
                        </button>
                    </div>
                </div>
            </div>
            <!--end handling multiple values-->

            <!--start validation error msg -->
            <div v-if="validationErroMsg !== null" class="text-danger">
                <small class="text-danger">{{ validationErroMsg }}</small>
            </div>
            <!--end validation error msg -->

        </div>
        <div v-if="isAllowMultipleValues()" class="row">
            <div class="col-xxl-6 mt-2">
                <button class="btn btn-light float-end" type="button" title="Add text field" @click="addTextForm()">
                    <i class="ri-add-fill" style="font-size: x-large;"></i>
                </button>
            </div>
        </div>

        <div v-if="hasCondition()">
            <ValueChanged :fieldValues="cbValues" :onValueChangedProperty="fieldData.onValueChanged"
                ref="conditionalElement">
            </ValueChanged>
        </div>
    </div>

</template>