import axios from 'axios';
import store from '@/state/store';

const httpClient = axios.create()
httpClient.interceptors.response.use(undefined, function unauthInterceptor(err) {

    console.log("response intercepted", err);


    if (err.response.status === 401) {
        console.log("error")
        store.dispatch('context/setUserLoggedOut');
        window.location.replace('/account/signIn');

        return Promise.reject(err);
    } else if (err.response.status < 200 || err.response.status >= 300) {
        return Promise.reject(err);
    }


    return Promise.resolve(err.response);

});

httpClient.interceptors.request.use(function (config) {
    // Do something before request is sent
    console.log('config', config);
    return config;
}, function (error) {
    // Do something with request error
    return Promise.reject(error);
});

export default {

    async callUrl(absoluteUrl) {
        return httpClient.get(absoluteUrl);
    },

    // websites API

    async listUserWebistes(orgId) {
        var listUserWebsitesUrl = "/api/websites";

        if (typeof orgId !== "undefined") {
            listUserWebsitesUrl += `?organisationId=${orgId}`;
        }

        return httpClient.get(listUserWebsitesUrl);
    },

    async getFeature(pathToFeature) {
        return httpClient.get(pathToFeature);
    },

    async websiteFeatureList() {
        const featureListUrl = "/api/metadata/features";
        console.log(httpClient.interceptors);
        return httpClient.get(featureListUrl);
        // .catch(e => {
        //     console.log(e);
        // })
    },

    async addWebsite(url) {
        let addWebsiteUrl = "/api/websites";
        var body = {};
        body.websiteUrl = url;
        return httpClient.post(addWebsiteUrl, body);
    },

    async getWebsiteInfo(websiteId) {
        console.log(websiteId);
        let getWebsite = `/api/websites/${websiteId}`;
        return httpClient.get(getWebsite);
    },

    async startCrawl(websiteId) {
        const startCrawlUrl = `/api/websites/${websiteId}/crawls/`;
        return httpClient.post(startCrawlUrl, {});
    },

    async getCrawlSummary(websiteId) {
        const startCrawlUrl = `/api/websites/${websiteId}/crawls/`;
        return httpClient.get(startCrawlUrl);
    },

    // webpage API

    async getAllPages(websiteId) {
        const listUserWebsitesUrl = `/api/websites/${websiteId}/pages`;
        return httpClient.get(listUserWebsitesUrl);
    },

    async getEditPagesOnly(websiteId) {
        const listUserWebsitesUrl = `/api/websites/${websiteId}/pages?onlyEditPages=true`;
        return httpClient.get(listUserWebsitesUrl);
    },

    async getPageInfo(websiteId, pageId, orgId) {
        var getPageInfoUrl = `/api/websites/${websiteId}/pages/${pageId}`;

        if (typeof orgId !== 'undefined') {
            getPageInfoUrl += `?organisationId=${orgId}`;
        }

        return httpClient.get(getPageInfoUrl);
    },

    async addWebPagMetaData(data) {
        const addWebPagMetaData = `/api/metadata/${data.websiteId}/${data.webpageId}`;
        return httpClient.post(addWebPagMetaData, data);
    },

    async batchAddWebPagMetaData(data) {
        const addWebPagMetaData = `/api/metadata/batch`;
        return httpClient.post(addWebPagMetaData, data);
    },

    async getWebsiteMetaData(websiteId, featureId, status, foramtUsingTreeView) {
        const getWebsiteMetaDataUrl = `/api/metadata/${websiteId}`;

        return httpClient.get(getWebsiteMetaDataUrl, { params: { featureId: featureId, status: status, formatTreeView: foramtUsingTreeView } });
    },

    async addWebPageUrls(websiteId, data) {
        const addWebPagMetaData = `/api/websites/${websiteId}/pages`;
        return httpClient.post(addWebPagMetaData, data);
    },

    async upsertWebPageUrls(websiteId, data) {
        const addWebPagMetaData = `/api/websites/${websiteId}/pages`;
        return httpClient.put(addWebPagMetaData, data);
    },

    async getPageMetaData(websiteId, pageId, featureId) {

        let getPageMetaDataUrl = '/api/metadata/' + websiteId + '/' + pageId + '?featureId=' + featureId + '&autocomplete=true';
        console.log('getPageMetaDataUrl:', getPageMetaDataUrl);
        return httpClient.get(getPageMetaDataUrl);
    },

    async getAllTrackedPages(websiteId, orgId) {
        const getAllTrackedPagesUrl = `/api/websites/${websiteId}/pages/track?orgId=${orgId}`;
        return httpClient.get(getAllTrackedPagesUrl);
    },

    async trackPage(orgId, websiteId, pageId) {
        const trackPageUrl = `/api/websites/${websiteId}/pages/${pageId}/track`;
        console.log(trackPageUrl);
        return httpClient.post(trackPageUrl, {
            orgId: orgId
        });
    },

    async removeTrackedPages(orgId, websiteId, pageIds) {
        const removeTrackPageUrl = `/api/websites/${websiteId}/track`;
        console.log(removeTrackPageUrl);
        return httpClient.delete(removeTrackPageUrl, {
            data: {
                orgId: orgId,
                pageIds: pageIds
            }
        });
    },

    async auditPage(websiteId, pageId) {
        const auditPageUrl = `/api/websites/${websiteId}/pages/${pageId}/audits`;
        return httpClient.get(auditPageUrl);
    },

    async checkScriptStatus(websiteId, pageId) {
        const auditPageUrl = `/api/websites/${websiteId}/pages/${pageId}/scripts`;
        return httpClient.get(auditPageUrl);
    },

    // meta data API

    async getMetaDataForm(featureFormUrl) {
        let url = "/api" + featureFormUrl;
        return httpClient.get(url);
    },

    async submitMetaDataForm(data) {
        let url = `/api/metadata/${data.websiteId}/${data.webpageId}`;
        return httpClient.post(url, data);
    },

    async deleteMetaData(websiteId, webpageId) {
        let url = `/api/metadata/${websiteId}/${webpageId}`;
        return httpClient.delete(url);
    },

    async getJsonLd(websiteId, webpageId) {
        let url = `/api/metadata/${websiteId}/${webpageId}/jsonld`;
        return httpClient.get(url);
    },

    async getWebsiteRules(websiteId) {
        let url = '/api/metadata/rules';
        return httpClient.get(url, {
            params: {
                websiteId: websiteId
            }
        });
    },

    async createWebsiteRule(rulesObj) {
        let url = `/api/metadata/rules`;
        return httpClient.post(url, rulesObj);
    },

    async getWebsiteDefaultSettings(websiteId) {
        let url = `/api/metadata/defaults/${websiteId}`;
        return httpClient.get(url);
    },

    async upsertWebsiteDefaultSettings(websiteId, settingsObj) {
        let url = `/api/metadata/defaults/${websiteId}`;
        return httpClient.put(url, settingsObj);
    },

    async autoGenerateMetaData(websiteId) {
        let url = `/api/metadata/${websiteId}/crawl`;
        return httpClient.post(url);
    },

    // user api

    async registerUser(registerObj) {
        let url = "/api/users";
        return httpClient.post(url, registerObj);
    },

    async getUserProfile() {
        let url = "/api/users/profile";
        return httpClient.get(url);
    },

    async logout() {
        let url = "/api/users/token"
        return httpClient.delete(url);
    },

    // sitemaps api
    async getSitemapUrls(websiteId) {
        let url = `/api/sitemaps/${websiteId}`;
        return httpClient.get(url);
    },

    async authGoogle() {
        let url = "/api/googleAuth";
        return httpClient.get(url);
    },

    //organisation API
    async associateOrgAndWebsiteWithUser(requestBody) {
        let addWebsiteUrl = "/api/organisations";
        return httpClient.post(addWebsiteUrl, requestBody);
    },

    async getOrganisations() {
        let url = "/api/organisations";
        return httpClient.get(url);
    },

    // Apps Api
    async getAllStoreApps(orgId) {
        var storeUrl = `/api/apps/store?organizationId=${orgId}`;
        return httpClient.get(storeUrl);
    },

    async getAlAppsInOrg(orgId) {
        var storeUrl = `/api/apps?organizationId=${orgId}`;
        return httpClient.get(storeUrl);
    },

    async getAppData(appId, orgId, websiteId, pageId, appParams) {
        let url = `/api/apps/${appId}/data?orgId=${orgId}&websiteId=${websiteId}&pageId=${pageId}&appParams=${appParams}`;
        console.log("getAppData url:", url);
        return httpClient.get(url);
    },

    async getAppInfo(appId, orgId, websiteId) {
        let url = `/api/Apps/${appId}?orgId=${orgId}&websiteId=${websiteId}`;
        return httpClient.get(url);
    },

    async sendAppCommand(appId, orgId, websiteId, pageId, commandParams) {
        let url = `/api/Apps/${appId}/commands?orgId=${orgId}&websiteId=${websiteId}&pageId=${pageId}`;
        return httpClient.post(url, commandParams);
    },

    async uninstallApp(appId, orgId, websiteId) {
        let url = `/api/Apps/${appId}?orgId=${orgId}&websiteId=${websiteId}`;
        return httpClient.delete(url);
    },

    // Web page editor
    async AddWebPageEdit(pageId, dataObj) {
        var addWebPageEditUrl = `/api/editwebpages/${pageId}`;
        return httpClient.post(addWebPageEditUrl, dataObj);
    },

    async updateWebPageEditorDrafts(pageId, dataObj) {
        var addWebPageEditUrl = `/api/editwebpages/${pageId}`;
        return httpClient.put(addWebPageEditUrl, dataObj);
    },

    async getWebPageEditDrafts(websiteId) {
        var getDraftsUrl = `/api/editwebpages/drafts?websiteId=${websiteId}`;
        return httpClient.get(getDraftsUrl);
    },

    async getElementsForPageEdits(pageId) {
        var getDraftsUrl = `/api/editwebpages/drafts?pageId=${pageId}`;
        return httpClient.get(getDraftsUrl);
    },

    async deleteElementsForPageEdits(websiteId, dataObj) {
        var getDraftsUrl = `/api/editwebpages/${websiteId}`;
        console.log(dataObj);
        return httpClient.delete(getDraftsUrl, {
            data: dataObj
        });
    }
}