import axios from 'axios';

export const state = {
    profile: {},
    currentWebsite: {},
    currentOrganisation: {},
    userWebsiteList : [],
    userOrgList: []
};

export const getters = {
    isAuthenticated: state => {
        let isLoggedIn =  window.localStorage.getItem('isLoggedIn');
        console.log(typeof isLoggedIn !== 'undefined', isLoggedIn,  state.profile.email);
        return typeof isLoggedIn !== 'undefined' && isLoggedIn === 'true' 
       // console.log("email ", state.profile);
        //return  state.profile.email 
    },

    selectedWebsiteObj: state => {
        return state.currentWebsite;
    },

    selectedWebisteId: state => {
        return state.currentWebsite.id;
    },
    selectedWebsiteUrl: state=> {
        return state.currentWebsite.websiteUrl;
    },
    selectedOrganisationObj: state => {
        return state.currentOrganisation;
    },
    selectedOrganisationId: state => {
        return state.currentOrganisation.id;
    },

    userOrgArray: state => {
        return state.userOrgList;
    },

    userWebsiteArray: state => {
        return state.userWebsiteList;
    }
};

export const mutations = {
    setProfile (state, profile) {
        console.log("setting", profile);
        state.profile = profile
      },
    setCurrentWebsite(state, websiteObj) {
        console.log('context', websiteObj);
        state.currentWebsite = websiteObj;
    },

    setCurrentOrganisation(state, orgObj) {
        console.log('setCurrentOrganisation', orgObj);
        state.currentOrganisation = orgObj;
    },

    setUserOrgArray(state, orgArray) {
        console.log('userOrgList', orgArray);
        state.userOrgList = orgArray;
    },

    setUserWebsiteArray(state, websiteArray) {
        console.log('setUserWebsiteArray', websiteArray);
        state.userWebsiteList = websiteArray;
    }

};

export const actions = {
    login({ commit }, {email, password}) {
        const params = new URLSearchParams();
        params.append('grant_type', 'password');
        params.append('username', email);
        params.append('password', password);

        return new Promise((resolve, reject) => {
            axios.post('api/users/token?useCookie=true', params)
                .then(res => {
                    var profile = {}
                     profile.email = email;
                    commit('setProfile', profile);
                    window.localStorage.setItem('isLoggedIn', true)
                    resolve(res);
                })
                .catch(error => {
                    reject(error);
                });
        });
    },

    async logout() {
        return axios.post('account/logout').then(() => {
          this.setUserLoggedOut();
        })
    },

    setUserLoggedOut({commit}){
        commit('setProfile', {})
        window.localStorage.setItem('isLoggedIn', false)
    },

    setSelectedWebsite({commit}, {website}) {
       commit('setCurrentWebsite', website);
    },

    setUserWebsiteArray({commit}, {websiteArray}) {
        console.log('setUserWebsiteArray', websiteArray);
        commit('setUserWebsiteArray', websiteArray);
    },

    setSelectedOrganisation({commit}, {organisation}) {
        commit('setCurrentOrganisation', organisation);
    },

    setUserOrgArray({commit}, {orgArray}) {
        console.log('userOrgList', orgArray);
        commit('setUserOrgArray', orgArray);
    },

    restoreContext({commit}) {
        return new Promise((resolve, reject) => {
            axios.get('api/users/auth')
                .then(res => {
                    var profile = {}
                    profile.email = res.data.email;
                    console.log("profile: ", profile);

                    commit('setProfile', profile);
                    resolve(res);
                })
                .catch(error => {
                    window.localStorage.setItem('isLoggedIn', false)
                    reject(error);
                });
        });
    }
};
